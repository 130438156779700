<template>
  <NuxtLink
    itemscope
    itemtype="https://schema.org/Product"
    :to="path"
    :class="['product-thumb', `product-thumb-layout-${layout}`]"
    @click="$emit('onClick')"
  >
    <meta itemprop="url" :content="path" />

    <div
      class="product-thumb-image-wrap"
      :class="( isBlendingTaxon || isBlendingElement ) && 'product-thumb-image-wrap-circle'"
    >
      <div
        v-if="['default', 'blank'].includes(layout) && isCleanScent"
        class="product-thumb-highlights"
      >
        <CleanScents
          v-if="isCleanScent"
          icon-only
          icon="square"
          class="product-thumb-clean-scents"
        />
      </div>
      <div
        v-if="['default', 'blank'].includes(layout) && productLabels.length"
        class="product-thumb-labels"
      >
        <ProductLabel
          v-for="label in productLabels"
          :key="label.name"
          class="product-thumb-label"
          :type="label.slug"
          aria-hidden="true"
          :text="label.name"
        />
      </div>

      <ProductPicture
        :discontinued="isDiscontinued && ['default', 'blank'].includes(layout)"
        html-role="none"
        loading="lazy"
        class="product-thumb-image"
        :image="{ src: src, alt: alt, srcset: srcset }"
        :sizes="sizes"
      />
    </div>

    <div class="content" :class="!priceRange.length && !rating && 'no-meta'">
      <div class="product-thumb-name" itemprop="name">
        {{ name }}<span class="at-text">.</span>
      </div>
      <span v-for="label in productLabels" :key="label.name" class="at-text">
        This product is {{ label }}.
      </span>
      <span v-if="isCleanScent" class="at-text">
        This is a CleanScent Fragrance.
      </span>
      <template v-if="!(layout === 'small')">
        <div class="product-thumb-meta">
          <Rating
            v-show="layout === 'default'"
            class="product-thumb-rating"
            :rating="rating"
            :review-count="reviewCount"
            itemprop="aggregateRating"
          />
          <template v-if="priceRange.length">
            <span class="at-text"> starting at ${{ priceRange[0] }} </span>
            <span v-if="price" class="price" aria-hidden="true">
              {{ price }}
            </span>
          </template>
        </div>
      </template>
      <span
        v-if="description && layout === 'review'"
        class="product-thumb-description"
      >
        {{ formatDesc }}
      </span>
    </div>
  </NuxtLink>
</template>

<script>
  import { createProductTags } from "~/lib/createProductTags.js"

  export default {
    props: {
      taxons: {
        type: Array,
        required: false,
        default: () => [],
      },
      href: {
        type: String,
        required: true,
      },
      src: {
        type: String,
        default: "",
      },
      sizes: {
        type: String,
        default: null,
      },
      srcset: {
        type: Array,
        default: () => [],
      },
      alt: {
        type: String,
        default: "",
      },
      id: {
        type: [String, Number],
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      gaCategory: {
        type: String,
        default: "",
      },
      priceRange: {
        type: Array,
        required: false,
        default() {
          return []
        },
      },
      rating: {
        type: Number,
        default: 0,
      },
      layout: {
        type: String,
        default: "default",
        validator: (value) => {
          return ["default", "small", "card", "review", "blank"].includes(value)
        },
      },
      description: {
        type: String,
        required: false,
        default: "",
      },

      isCleanScent: {
        type: Boolean,
        default: false,
      },
      isBlendingElement: {
        type: Boolean,
        default: false,
      },
      isNew: {
        type: Boolean,
        default: false,
      },
      isOnClearance: {
        type: Boolean,
        default: false,
      },
      isDiscontinued: {
        type: Boolean,
        default: false,
      },
      isOnSale: {
        type: Boolean,
        default: false,
      },
      isBestseller: {
        type: Boolean,
        default: false,
      },
      oneOzSale: {
        type: Boolean,
        default: false,
      },
      marketingTaxons: {
        type: Array,
        default: () => [],
      },
      reviewCount: {
        type: Number,
        default: 0,
      },
    },
    emits: ["onClick"],
    setup() {
      return {
        BLENDING_ELEMENTS_TAXON_ID: "400",
      }
    },
    computed: {
      productLabels() {
        return createProductTags(this.marketingTaxons, this.isDiscontinued, 2)
      },
      path() {
        return this.href?.startsWith("/") ? this.href : `/${this.href}`
      },
      formatDesc() {
        return this.description.substring(0, 90) + "..."
      },
      price() {
        if (this.priceRange.length === 1) {
          return `$${parseFloat(this.priceRange[0], 8).toFixed(2)}`
        }
        return `$${parseFloat(this.priceRange[0], 8).toFixed(2)}+`
      },
      isBlendingTaxon(){
        return this.taxons?.find((taxon) => taxon.id === this.BLENDING_ELEMENTS_TAXON_ID)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .product-thumb {
    color: inherit;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:hover {
      .product-thumb-image-wrap {
        transform: scale(1.02) translate3d(0, 0, 0) perspective(1px)
          translateZ(0);
        box-shadow: $hover-shadow;
      }
    }
  }

  .product-thumb-image-wrap {
    position: relative;
    background: white;
    box-shadow: $shadow;
    border-radius: $border-radius-large;
    transition:
      transform 120ms ease-in-out,
      box-shadow 120ms ease-in-out;
    transform-origin: 50% 50%;
    will-change: transform, box-shadow;
    &-circle {
      border-radius: 50%;
    }
  }

  .product-thumb-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    overflow: hidden;
  }

  .product-thumb-highlights {
    position: absolute;
    display: flex;
    flex-direction: column;
    grid-gap: calc($base-spacing / 2);
    z-index: 5;
    right: $base-spacing * 3;
    top: $base-spacing * 3;
  }

  .product-thumb-clean-scents {
    overflow: hidden;
    display: block;
  }

  .product-thumb-labels {
    display: flex;
    flex-direction: column;
    gap: $base-spacing;
    align-items: flex-end;
    position: absolute;
    left: $base-spacing * 3;
    right: $base-spacing * 3;
    bottom: $base-spacing * 3;
    z-index: 5;
  }

  .product-thumb-meta {
    display: flex;
    grid-gap: $base-spacing;
    align-items: center;
  }

  .product-thumb-name {
    max-width: 100%;
    @include type("sm");

    .product-thumb-layout-blank & {
      width: 100%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    &:after {
      clear: both;
    }
  }

  .product-thumb-states {
    margin-bottom: $base-spacing;
    display: flex;
    grid-gap: $base-spacing;
    flex-flow: row wrap;
  }

  /* Default */
  .product-thumb-layout-default,
  .product-thumb-layout-blank {
    display: flex;
    flex-direction: column;

    .product-thumb-image-wrap {
      aspect-ratio: 1 / 1;
      width: 100%;
    }

    .product-thumb-name {
      font-weight: bold;
      align-items: center;
      grid-gap: $base-spacing;
    }

    .content {
      box-sizing: border-box;
      display: flex;
      flex: 1;
      padding: $base-spacing * 3 0;
      flex-direction: column;
      grid-gap: calc($base-spacing / 2);
      align-items: flex-start;

      min-height: $base-spacing * 20;
      &.no-meta {
        min-height: $base-spacing * 8;
      }
    }
    .price {
      margin-right: calc($base-spacing / 2);
      color: $gray-text;
      @include type("sm");
      transform: translateY(1px);
    }
  }

  /* Card */
  .product-thumb-layout-card {
    display: inline-grid;
    grid-template-columns: 64px 1fr;
    border-radius: $border-radius;
    background: white;
    box-shadow: $shadow;
    padding-right: $base-spacing * 3;
    grid-gap: $base-spacing * 4;
    .product-thumb-name {
      font-weight: bold;
    }
    .product-thumb-image {
      border-radius: $border-radius 0 0 $border-radius;

      &:hover {
        transform: initial;
        box-shadow: initial;
      }
    }
    .product-thumb-image-wrap {
      height: 100%;
      aspect-ratio: 1 / 1;
    }
    .content {
      margin: $base-spacing * 2 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  /* Small */
  .product-thumb-layout-small {
    display: flex;
    align-items: center;
    background: white;
    font-weight: bold;
    font-family: $font-family-default;
    .product-thumb-image-wrap {
      flex-shrink: 0;
      width: $base-spacing * 9;
      height: $base-spacing * 9;
      margin-right: $base-spacing * 3;
    }
    .product-thumb-name {
      padding-right: $base-spacing * 2;
    }
  }

  /* Review */
  .product-thumb-layout-review {
    display: flex;
    align-items: center;
    grid-gap: $space-s;
    background-color: $blue-100;
    border-radius: $border-radius;
    padding: $base-spacing * 8 $base-spacing * 6;
    .product-thumb-image-wrap {
      flex-shrink: 0;
      flex-grow: 1;
      width: $base-spacing * 16;
      height: $base-spacing * 16;
    }
    .product-thumb-name {
      font-weight: bold;
    }
    .content {
      display: block;
      padding-bottom: 0px;
    }
    .product-thumb-description {
      color: $gray;
    }
  }

  .at-text {
    @extend %at-text;
  }
</style>
