<template>
  <span>
    <SvgIcon
      :icon="selectedIcon"
      :class="['cleanscents', `cleanscents-icon-${icon}`]"
      alt="CandleScience CleanScents"
    />
  </span>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        default: "square",
        validator: (value) => {
          return ["square", "text"].includes(value)
        },
      },
    },

    computed: {
      selectedIcon() {
        switch (this.icon) {
          case "square":
            return "cleanscents"
          default:
            return "cleanscents-logotype"
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cleanscents {
    display: block;
  }
  .cleanscents-icon-square {
    height: 28px;
    width: 28px;
  }
  .cleanscents-icon-text {
    height: 44px;
    width: 198px;
  }
</style>
